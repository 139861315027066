import { addDocIn_fs36PaymentLinks, fs36PaymentLinks } from "../firestore_schemas/fs36PaymentLinks"
import { removeDocFrom_fs36PaymentLinks } from "../firestore_schemas/fs36PaymentLinks"
import { getDocFrom_fs36PaymentLinks } from "../firestore_schemas/fs36PaymentLinks"
import { setDocIn_fs36PaymentLinks } from "../firestore_schemas/fs36PaymentLinks"


type Result = {
  debug_data: Array<string>;
  return_msg: string;
  success: boolean;
  error_title: string;
  error_summary: string;
  error_next_steps: string;
  document_id?: string;
};

export async function fs36Writer(
  w_type: string,
  fs1_id: string,
  fs2_id: string,
  fs4_consumer_contact_uid: string,
  fs6_conversation_uid: string,
  fs36_uid: string,
  data={
    description: "", 
    adjusted_amount: 0,
    amount: 0, 
    order_number: "", 
    payment_status: "no_status", 
    payment_processor_id: 1, 
    terms_type_number: 1, 
    transaction_id: null, 
    refunded_amount: 
    null, 
    refund_amount: null
  }
) {

  const retval: Result = {
    debug_data: [],
    return_msg: "fs36_writer:fs36Writer ",
    success: true,
    error_title: "",
    error_summary: "",
    error_next_steps: "",
    document_id: ""
  };

  /// Validate arguments according to write type
  if (!fs1_id || !fs2_id || !fs4_consumer_contact_uid || !fs6_conversation_uid) {
    retval.success = false;
    retval.return_msg += "Insufficient arguments.";
    retval.error_title = "Insufficient arguments.";
    retval.error_summary = "business_uid or recycler_uid not set.";
    retval.error_next_steps = "Please provide correct business_uid snd recycler_uid.";
    return retval;
  }

  if((w_type === "d" || w_type === "e") && !fs36_uid) {
    retval.success = false;
    retval.return_msg += "fs36_uid argument missing.";
    retval.error_title = "fs36_uid argument missing.";
    retval.error_summary = "Employee feedback record UID missing.";
    retval.error_next_steps = "Please provide correct Employee feedback record UID.";
    return retval;
  }

  /// <END> Validate arguments according to write type

  // Delete record
  if (w_type == "d") {
    await removeDocFrom_fs36PaymentLinks(fs1_id, fs2_id, fs4_consumer_contact_uid,fs6_conversation_uid,fs36_uid);
    return retval
  }

  // Create new record
  if (w_type == "n") {
    let response = await addDocIn_fs36PaymentLinks(
      fs1_id,
      fs2_id,
      fs4_consumer_contact_uid,
      fs6_conversation_uid,
      {
        payment_link_description: data.description,
        amount: data.amount,
        order_number: data.order_number,
        payment_status: data.payment_status,
        terms_type_number: data.terms_type_number
      }
    );

    if (!response.id || response.id == "" ) {
      retval.success = false;
      retval.return_msg += "Firestore write error.";
      retval.error_title = "Firestore write error.";
      retval.error_summary = "FS36 record not created  in firestore.";
      retval.error_next_steps = "Please reload page and try again.";
      return retval;
    }
    retval.document_id = response.id;
    return retval
  }

   ///// Update existing record
   if (w_type == "e") {
    let response_data = await getDocFrom_fs36PaymentLinks(
      fs1_id,
      fs2_id,
      fs4_consumer_contact_uid,
      fs6_conversation_uid,
      fs36_uid
    )

    if (response_data.success === false) {
      retval.success = false;
      retval.return_msg += "Record not found.";
      retval.error_title = "Record not found.";
      retval.error_summary = "FS36 record not found in firestore.";
      retval.error_next_steps = "Please reload page and try again.";
      return retval;
    }

    const fs36Data: fs36PaymentLinks = {
      payment_link_uid: response_data['doc_data'].payment_link_uid,
      payment_link_url: response_data['doc_data'].payment_link_url,
      payment_link_description: data.description || response_data['doc_data'].payment_link_description,
      adjusted_amount: data.adjusted_amount || response_data['doc_data'].adjusted_amount,
      amount: data.amount || response_data['doc_data'].amount,
      order_number:  data.order_number || response_data['doc_data'].order_number,
      payment_status:  data.payment_status || response_data['doc_data'].payment_status,
      last_status_check_time: response_data['doc_data'].last_status_check_time || null,
      payment_processor_id: data.payment_processor_id || response_data['doc_data'].payment_processor_id,
      terms_type_number: data.terms_type_number || response_data['doc_data'].terms_type_number,
      transaction_id: data.transaction_id || response_data['doc_data'].transaction_id,
      refunded_amount: data.refunded_amount || response_data['doc_data'].refunded_amount,
      payment_paid_time: response_data['doc_data'].payment_paid_time || null,
      refund_amount: data.refund_amount || response_data['doc_data'].refund_amount,
      creation_date: response_data['doc_data'].creation_date,
      recent_transaction_details: response_data['doc_data'].recent_transaction_details || null,
      pickup_order: response_data['doc_data'].pickup_order || null,
      shipping_address_info: response_data['doc_data'].shipping_address_info || null,
    } as fs36PaymentLinks;

    let response = await setDocIn_fs36PaymentLinks(
      fs1_id,
      fs2_id,
      fs4_consumer_contact_uid,
      fs6_conversation_uid,
      fs36_uid,
      fs36Data
    )

    if (!response.id || response.id == "" ) {
      retval.success = false;
      retval.return_msg += "Firestore write error.";
      retval.error_title = "Firestore write error.";
      retval.error_summary = "FS36 record not updated in firestore.";
      retval.error_next_steps = "Please reload page and try again.";
      return retval;
    }
    retval.document_id = response.id;
    return retval
  }
  /////</end> Update existing record


}

function firestoreWriteError(error:any) {
  // TODO - complete this
}
